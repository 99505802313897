import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue"
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import OrderBackCard from "modules/base/components/priceCard/returnOrder/orderBackCard.vue";

export default {
  mixins: [ItemMixin],
  components: {
    "block-title": () => import('@/modules/base/components/blockTitle.vue'),
    customFormActionBtn,
    OrderBackCard
  },
  data: () => ({
    data: null,
  }),
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep(this.backItems)
      },
    },
  },
  computed: {
    providerId() {
      return this.$route.params.providerId
    },
    orderId() {
      return this.$route.params.orderId
    },
    orderConstants() {
      return this.props.orderConstants
    },
    // 訂單狀態
    status() {
      if (!this.propsValue) return
      return this.propsValue.status
    },
    backItems() {
      if (!this.propsValue) return []
      return this.propsValue.backItems
    },
    show() {
      return ['sended', 'finished', 'request_return', 'canceled'].includes(this.status)
    },
    showBackOrder() {
      if (!this.backItems) return false
      return this.backItems.length > 0
    },
    computedData() {
      return this.data.map((item, index) => {
        return {
          ...item,
          return_count: this.$helper.reverseShippingCount(item.count, item.shipping_unit),
          price: this.items[index].price,
          total: item.amount,
          discount_config: this.items[index].discount_config,
          shipping: this.items[index].shipping,
          amount: this.items[index].amount
        }
      })
    },
    backOrder() {
      if (!this.propsValue) return null
      return this.propsValue.backOrder
    },
    // 退貨狀態
    backOrderStatus() {
      if (!this.backOrder) return ''
      return this.backOrder.backOrder_status
    },
    backOrderStatusText() {
      if (this.status == 'finished' && this.backOrderStatus == 'SUCCESS') return '部分退貨完成'
      if (this.status == 'canceled' && this.backOrderStatus == 'SUCCESS') return '全部退貨完成'
      return this.$t(`back-order.status.${this.backOrderStatus}`)
    },
    // 退款總計
    amount() {
      if (!this.backOrder) return 0
      return Math.round(this.backOrder.total)
    },
    payment_type() {
      if (!this.propsValue) return null
      return this.propsValue.payment_type
    },
    showTotal() {
      if (['finished', 'canceled'].includes(this.status) && this.backOrderStatus == 'SUCCESS') {
        if (this.payment_type == 'monthly' && this.backItems.length > 0) return true
      }
      return false
    },
    // 購買項目
    items() {
      if (!this.propsValue) return []
      return this.propsValue.items
    },
    // 購買商品合計
    total_amounts() {
      return this.propsValue.total_amounts
    },
    show退款小計() {
      return this.status == 'finished'
    },
    show退款合計() {
      return this.status == 'finished'
    },
  },
  methods: {

  },
}