<template >
  <div v-if="show">
    <block-title>銷退紀錄</block-title>

    <template v-if="showBackOrder">
      <div>
        <v-row>
          <v-col class="d-flex align-center">
            <span
              >{{ "back-order.status" | t }}: {{ backOrderStatusText }}</span
            >
          </v-col>
          <v-col cols="4">
            <custom-form-action-btn
              :defaultKey="defaultKey"
              :action="{
                key: 'click',
                handler: goOrderBackDetail,
              }"
              :label="btnText"
              color="secondary"
              class="btn-min-width"
              :cols="{ cols: 4 }"
            ></custom-form-action-btn>
          </v-col>
        </v-row>
      </div>

      <template v-if="backOrderStatus != 'REJECT'">

        <order-back-card
            v-for="(item, index) in computedData"
            :key="item.item_id"
            v-model="computedData[index]"
            :configs="cardConfigs(index)"
        ></order-back-card>

        <bar v-if="show退款合計" class="" type="primary">
          <template v-slot:label> 退款合計: </template>
          <template v-slot:value>{{ amount }} 元</template>
        </bar>

        <bar v-if="showTotal" class="mt-10" type="secondary">
          <template v-slot:label> 總計: </template>
          <template v-slot:value>{{ total }} 元</template>
        </bar>
      </template>
    </template>

    <p v-else>暫無紀錄</p>
  </div>
</template>

<script lang="babel" type="text/babel">
import BackRecords from "@/mixins/BackRecords.js"

export default {
  mixins: [BackRecords],
  computed: {
    show() {
      return !['created', 'apply'].includes(this.status)
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
    btnText() {
      if(this.backOrderStatus == 'APPLY') return '回覆'
      return '查看明細'
    },
    total() {
      return Math.round(this.total_amounts - this.amount)
    },
  },
  methods: {
    cardConfigs(index) {
      const item = this.data[index]
      const config = [
        { label: item.product, colon: false, labelColor: "primary--text" },
        {
          id: "return_count",
          label: "退貨數量",
          col: 6,
        },
        {
          id: "price",
          valueSuffix: "元",
          col: 6
        },
      ]
      if(this.show退款小計) {
        config.push(
            {
              id: "total",
              label: "退款小計",
              type: "total",
              barType: 'gray',
              valueSuffix: "元",
              value: item.amount,
            },
        )
      }
      return config
    },
    goOrderBackDetail() {
      this.$router.push({
        name: 'provider-backOrder-detail',
        params: {
          providerId: this.providerId,
          orderId: this.orderId,
        },
      })
    },
  },
}
</script>

